import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const BuildingMaterialsProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Building Materials
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/building-materials/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/building-materials/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/building-materials/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/building-materials/3.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>


        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-5 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply carries a large variety of building materials.  Ranging from interior to exterior materials, we have the supplies that cater to construction projects.  Norhart Supply provides materials for all stages of construction.  From steel studs and drywall to siding, roofing and decking, we have the materials you need.
            <p/>

            <strong>Steel Studs </strong>
            <br/>
            Structural steel studs, or metal studs, are commonly used as an alternative to traditional wood studs due to their increased durability and longevity as they do not rot and are not flammable.  Steel studs are designed to frame and support both interior and exterior walls. Steel studs come in a variety of sizes, ranging from 25 gauge to 16 gauge in thickness, as well as varying lengths.
            <p/>
            <hr/>

            <strong>Insulation </strong>
            <br/>
            Norhart Supply carries a range of insulation products to fit the needs of the project you are completing.  Each type of insulation has different features, specifically the thermal resistance it possesses.  The thermal resistance, or resistance to the flow of heat, of insulation is known as “R-Value.”  The higher the R-Value, the better the insulator.
            <p/>
            <hr/>

            <strong>Blanket Batts & Rolls</strong>
            <br/>
            Blanket batts and rolls are the most standard type of insulation.  This type of insulation comes in 2 types—Standard and high-performance.  Standard fiberglass blankets and batts have an R-Value of 2.9-3.8 per inch of thickness.  High-performance (medium-density and high-density) fiberglass blankets have an R-Value of 3.7-4.3 per inch of thickness.
            <p/>
            <hr/>

            <strong>Spray Foam Insulation (Open-cell Foam & Denser Closed-cell)</strong>
            <br/>
            Spray foam insulation is ideal for adding insulation to finished areas, irregularly shaped areas and hard to reach spaces.  There are 2 kinds of spray foam insulation—Open-cell foam and closed-cell foam.  Open-cell foam has an R-Value of 3.7 per inch of thickness as it is less dense. Closed-cell foam is denser than open-cell foam giving it a higher R-Value of 6.2 per inch of thickness.
            <p/>
            <hr/>

            <strong>Foam Board / Rigid Foam Panels </strong>
            <br/>
            Foam board, or rigid foam panels, is used best for unfinished walls, floors, and ceilings.  Rigid foam panels are regularly used as a part of both exterior and interior sheathing.  This kind of insulation has an R-Value of 4-6.5 per inch of thickness.
            <p/>
            <hr/>

            <strong>Mineral Wool </strong>
            <br/>
            Mineral wool insulation is great for residential wood-framed homes and structures. Like standard fiberglass blanket batts and rolls, mineral wool insulation also comes in rolls of batts.  This type of insulation has an R-Value of 3.3 per inch of thickness.
            <p/>
            <hr/>

            <div className="mb-5"></div>

            <h4>Drywall Products</h4>
            <br/>
            Norhart Supply provides the right drywall products for the multifamily industry. Our products include drywall, joint compound, and joint tape.  These products are used for the beginning phases of completing the interior of your home, following the installation of insulation.

            <p/>
            <hr/>

            <strong>Drywall</strong>
            <br/>
            We supply a variety of sizes of drywall, but primarily focus on 5/8” thick sheets measuring 4’ by 12’.  We carry Type X, Type C, mold & mildew resistant and exterior fiberglass sheathing drywall.
            <p/>
            <hr/>

            <strong>Type X</strong>
            <br/>
            Type X drywall is commonly used in fire-rated locations like walls and ceilings because of the glass fibers added to its gypsum core making it stronger in fire conditions.  Type X drywall has a one-hour fire rating.  This kind of drywall meets ASTM International standards.  Type X drywall is only available in widths of 48” & 54” with lengths up to 16 ft.
            <p/>
            <hr/>

            <strong>Type C</strong>
            <br/>
            Like Type X drywall, Type C is also fire-rated and has the same fire rating.  However, the difference lies in the composition of the drywall. Type C drywall contains more glass fibers and the mineral vermiculite.  Vermiculite is a noncombustible material that expands when heated, giving it the ability to reduce panel shrinkage and replace water loss in fire conditions.
            <p/>
            <hr/>

            <strong>Mold and Mildew Resistant</strong>
            <br/>
            Mold and mildew resistant works to prevent the growth of mold and mildew.  This kind of drywall is ideal for areas such as bathrooms and kitchens where humidity and moisture can be found. Mold and mildew resistant drywall has a fiberglass facing material, rather than paper which allows mold and mildew to spread more easily.
            <p/>
            <hr/>

            <strong>Exterior Fiberglass Sheathing </strong>
            <br/>
            Exterior fiberglass sheathing is used to help the exterior walls of a structure resist water and air.  It always helps to resist noise infiltration from outside.  Exterior fiberglass sheathing has a moisture resistant and noncombustible core.  It has these properties due to both front and back glass-mat facers.
            <p/>
            <hr/>

            <strong>Joint Compound</strong>
            <br/>
            Norhart Supply carries various types of joint compound, or drywall mud, to fit your needs.  Joint compound is used together with joint tape to cover the seams of sheets of drywall.  Joint compound can also be used to patch holes and other blemishes to a wall's surface.
            <p/>
            <hr/>

            <strong>All-Purpose</strong>
            <br/>
            All-purpose joint compound can be used for all phases of the drywall process.  Unlike other kinds of joint compound, all-purpose can be used as a topping coat.  This kind of drywall mud is lightweight, with a slower drying time. This slower drying time allows the mud to be more forgiving, providing more time to correct mistakes.
            <p/>
            <hr/>

            <strong>Lightweight</strong>
            <br/>
            Lightweight drywall mud has a low shrinkage rate and is easy to work with.  This kind of mud has a slightly quicker dry time.  Lightweight joint compound requires fewer coats than a heavier compound to reach the same result.  It smooths very easily and is intended to be used as a finish coat.
            <p/>
            <hr/>

            <strong>Quick Set</strong>
            <br/>
            Quick set joint compound is most used for patching & filling deep cracks and holes.  Quick set mud sets by a chemical reaction rather than the moisture evaporating.  Due to this chemical reaction, quick set mud works effectively in humid conditions whereas other types of mud may not work as efficiently in the same conditions.
            <p/>
            <hr/>

            <strong>Tape</strong>
            <br/>
            Drywall tape, or joint tape, is used in combination with joint compound.  Together, these materials are used to cover seams between separate pieces of drywall.  Paper and mesh tape come in rolls, whereas corner bead and expansion bead come in pieces up to 12 ft. long.
            <p/>
            <hr/>

            <strong>Joint Tape</strong>
            <br/>
            Paper joint tape needs to be embedded in a layer of joint compound.  Paper joint tape is best used in combination with ready mix or setting-type drywall mud.  Joint tape is used together with drywall mud in order to cover the seams between pieces of drywall.
            <p/>
            <hr/>

            <strong>Mesh Tape</strong>
            <br/>
            Mesh tape has a tacky backing and can self-adhere to a wall.  This kind of tape creates stronger joints than typical paper joint tape.  Mesh tape also eliminates the need for a base coat of drywall mud.  The open-fiber makeup of this tape helps eliminate bubbles and dry spots.
            <p/>
            <hr/>

            <strong>Corner Bead</strong>
            <br/>
            Corner bead is an angled piece oof trim installed in the corner of a wall where 2 pieces of drywall meet, covering the seams between separate pieces of drywall.  It is used to stabilize and reinforce corners.  Corner bead comes in pieces up to 12 ft. long and should be applied in one solid piece when possible.
            <p/>
            <hr/>

            <strong>Expansion Bead </strong>
            <br/>
            Expansion bead is used to prevent cracking in drywall as it expands and contracts as it follows the movement of the drywall.  Drywall can expand and contract due to several influences—Temperature, moisture, building movement, damage, settlement and flexing.  The flexibility of expansion bead provides protection against the stress of contraction and expansion.
            <p/>
            <hr/>

            <div className="mb-5"></div>

            <h4>Exterior Materials</h4>
            <br/>
            Norhart Supply has a variety of exterior building materials.  Siding and roofing are the most important finishing pieces to the exterior of any building.  We carry different types of roofing and siding to complete the exterior of your structure.  Norhart Supply also carries decking and railing to add extra appeal, especially in the multifamily industry.
            <p/>
            <hr/>

            <strong>Siding</strong>
            <br/>
            Expansion bead is used to prevent cracking in drywall as it expands and contracts as it follows the movement of the drywall.  Drywall can expand and contract due to several influences—Temperature, moisture, building movement, damage, settlement and flexing.  The flexibility of expansion bead provides protection against the stress of contraction and expansion.
            <p/>
            <hr/>

            <strong>Roofing</strong>
            <br/>
            Norhart Supply carries various roofing systems for flat roofs, such as TPO roofing membrane, EPDM rubber roofing membrane and PVC roofing membrane. These systems are considered single-ply membrane.  When properly installed and maintained, single-ply membrane roofing can last up to 30 years.
            <p/>
            <hr/>

            <strong>TPO</strong>
            <br/>
            TPO, also known as Thermoplastic Polyolefin, is one kind of single-ply roofing membrane.  TPO is typically bright white and very reflective. The seams of this roofing membrane are heat-welded together to prevent leakage.
            <p/>
            <hr/>

            <strong>EPDM</strong>
            <br/>
            EPDM, or Ethylene Propylene Diene Terpolymer, is another type of single-ply roofing membrane.  Like TPO, EPDM is also heat-welded together to prevent leakage. The difference between TPO and EPDM lies in the color.  EPDM is typically black or very dark, commonly referenced to as “Black Roofs.”
            <p/>
            <hr/>

            <strong>PVC</strong>
            <br/>
            PVC, or polyvinyl chloride, is also a kind of single-ply membrane.  Like TPO, PVC is white and reflective.  PVC is better used in warmer temperature areas as it does not hold up well against cold weather.
            <p/>
            <hr/>

            <strong>Decking & Railing</strong>
            <br/>
            Norhart Supply carries the materials to add decks to any building.  We carry both decking and railing to build and create decks and patios.  Decking comes in a variety of colors and sizes to fit the needs of the deck at hand.  Added features like decks have a great appeal, both visually and in use.
            <p/>

          </div>
        </div>
      </div>
    </section>
  )
}

export default BuildingMaterialsProductSection
